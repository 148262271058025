import { StringValuedMetric } from 'ts/commons/components/MetricFormatters';
import { MetricFormatterBase } from 'ts/commons/formatter/MetricFormatterBase';
import type { CounterSet } from 'typedefs/CounterSet';
import type { MetricDirectorySchemaEntry } from 'typedefs/MetricDirectorySchemaEntry';

/** A formatter for counter sets. */
export class CounterSetFormatter extends MetricFormatterBase<CounterSet> {
	public constructor(schemaEntry: MetricDirectorySchemaEntry) {
		super(schemaEntry);
	}

	public override formatValueAsJsx(value: CounterSet): JSX.Element {
		return <StringValuedMetric value={this.formatValueAsText(value)} />;
	}

	public override formatValueAsText(value: CounterSet): string {
		return Object.keys(value.map).sort().join(', ');
	}

	public override parseFromString(value: string): CounterSet {
		const elements = value.split(', ');
		const map = elements.reduce(
			(acc, key) => {
				acc[key] = 1;
				return acc;
			},
			{} as Record<string, number>
		);
		return { map, total: elements.length };
	}

	public override compare(a: CounterSet, b: CounterSet): number {
		const aKeys = Object.keys(a.map).sort();
		const bKeys = Object.keys(b.map).sort();
		if (aKeys.length !== bKeys.length) {
			return aKeys.length - bKeys.length;
		}
		for (let i = 0; i < aKeys.length; i++) {
			const aKey = aKeys[i]!;
			const bKey = bKeys[i]!;
			let compare = aKey.localeCompare(bKey);
			if (compare === 0) {
				compare = a.map[aKey]! - b.map[bKey]!;
			}
			if (compare !== 0) {
				return compare;
			}
		}
		return 0;
	}
}
