import type { AssessmentFormatterOptions } from 'ts/commons/formatter/AssessmentFormatter';
import type { NumericFormatterOptions } from 'ts/commons/formatter/NumericValueFormatter';
import { type EMetricValueTypeEntry } from 'typedefs/EMetricValueType';
import type { MetricDirectorySchemaEntry } from 'typedefs/MetricDirectorySchemaEntry';
import type { MetricValue } from 'typedefs/MetricValue';

export type MetricFormatterOptions = NumericFormatterOptions & {
	subType?: EMetricValueTypeEntry;
} & AssessmentFormatterOptions;

/** The base class for the formatters. */
export abstract class MetricFormatterBase<VALUE_TYPE extends MetricValue> {
	protected constructor(protected readonly schemaEntry: MetricDirectorySchemaEntry) {}

	/**
	 * Takes the given value and formats it to a React component (i.e., to render it on the page). Uses the formatting
	 * {@link options} if applicable.
	 */
	public abstract formatValueAsJsx(value: VALUE_TYPE, options: MetricFormatterOptions): JSX.Element;

	/**
	 * Takes the given value and formats it as string (e.g., for tooltips). Uses the formatting {@link options} if
	 * applicable.
	 */
	public abstract formatValueAsText(value: VALUE_TYPE, options: MetricFormatterOptions): string;

	/**
	 * Parses a metric value from a string, e.g., from a metric treemap. The format is whatever the toString
	 * implementation on the backend produces for the metric.
	 */
	public abstract parseFromString(value: string): VALUE_TYPE;

	/** Compares two metric values for sorting */
	public abstract compare(a: VALUE_TYPE, b: VALUE_TYPE): number;
}
