import { Assertions } from 'ts/commons/Assertions';
import { StringValuedMetric } from 'ts/commons/components/MetricFormatters';
import { MetricFormatterBase } from 'ts/commons/formatter/MetricFormatterBase';
import type { MetricDirectorySchemaEntry } from 'typedefs/MetricDirectorySchemaEntry';

/** A formatter for string values. */
export class StringValueFormatter extends MetricFormatterBase<string> {
	public constructor(schemaEntry: MetricDirectorySchemaEntry) {
		super(schemaEntry);
	}

	public override formatValueAsJsx(value: string): JSX.Element {
		Assertions.assertString(value);
		return <StringValuedMetric value={value} />;
	}

	/** @inheritDoc */
	public override formatValueAsText(value: string): string {
		Assertions.assertString(value);
		return value;
	}

	public override parseFromString(value: string): string {
		return value;
	}

	public override compare(a: string, b: string): number {
		return a.localeCompare(b);
	}
}
