import { Assertions } from 'ts/commons/Assertions';
import { StringValuedMetric } from 'ts/commons/components/MetricFormatters';
import { EMetricName } from 'ts/commons/EMetricName';
import { DurationUtils } from 'ts/commons/formatter/DurationUtils';
import {
	getColorRepresentationForSemanticColor,
	TRAFFIC_LIGHT_GREEN,
	TRAFFIC_LIGHT_ORANGE,
	TRAFFIC_LIGHT_RED,
	TRAFFIC_LIGHT_YELLOW_ALTERNATIVE
} from 'ts/perspectives/tests/pareto/data/ColorSemantics';
import type { ETrafficLightColorEntry } from 'typedefs/ETrafficLightColor';
import type { MetricDirectorySchemaEntry } from 'typedefs/MetricDirectorySchemaEntry';
import { MetricProperties } from './../MetricProperties';
import { MetricFormatterBase, type MetricFormatterOptions } from './MetricFormatterBase';

/** The options supported by this formatter. */
export type NumericFormatterOptions = Partial<{
	/** Whether non-ratio values should be abbreviated (e.g, 1000 -> 1k). Defaults to {@code true}. */
	abbreviateValues: boolean;
	/**
	 * Whether the value should be interpreted as bytes and thus abbreviation must divide by 1024 instead of 1000.
	 * Defaults to {@code false}.
	 */
	valueIsBytes: boolean;
	/** The number of decimal places to use. Defaults to {@code 1}. */
	decimalPlaces: number;
	/** Whether positive numbers should be rendered with a sign as well. Defaults to {@code false}. */
	alwaysShowSign: boolean;
	/** Optional: The rating for the numeric value (e.g. 'YELLOW'). */
	rating: ETrafficLightColorEntry;
}>;

/** Describes how numbers should be treated during formatting (e.g. when rounding) */
export type NumberFormatInfo = {
	maxDecimals: number;
	minDecimals?: number;
};

/** A formatter for numeric values. */
export class NumericValueFormatter extends MetricFormatterBase<number> {
	public constructor(schemaEntry: MetricDirectorySchemaEntry) {
		super(schemaEntry);
	}

	/** Parses the given number string as float value. */
	public static parse(numberString: string): number {
		return parseFloat(numberString);
	}

	/** Formats the given number, using a max. decimal number of 1 per default. */
	public static formatNumberDefault(value: number, maxDecimals = 1): string {
		return NumericValueFormatter.roundWithMaxDecimals(value, maxDecimals).toLocaleString('en');
	}

	private static roundWithMaxDecimals(value: number, maxDecimals: number): number {
		return Number(Math.round(Number(value + `e+${maxDecimals}`)) + `e-${maxDecimals}`);
	}

	/**
	 * Formats the given value as percentage (e.g. 2 -> 200%). Will also append the '%' sign. If the value were rounded
	 * from a value not equal to 1 to a value of 1, the percentage value will be corrected to 99.9% (TS-32703)
	 */
	public static formatAsPercentage(
		value: number,
		roundingInfo: NumberFormatInfo = {
			minDecimals: 1,
			maxDecimals: 1
		}
	): string {
		let roundedValue = NumericValueFormatter.roundWithMaxDecimals(value * 100.0, roundingInfo.maxDecimals);
		if (roundedValue === 100 && value < 1) {
			roundedValue -= 0.1;
		}
		return (
			roundedValue.toLocaleString('en', {
				minimumFractionDigits: roundingInfo.minDecimals
			}) + '%'
		);
	}

	public override formatValueAsJsx(value: number, options: MetricFormatterOptions): JSX.Element {
		const formattedValue = this.formatValueAsText(value, options);
		let tooltip: string | undefined = this.formatValueAsText(value, {
			...options,
			abbreviateValues: false,
			decimalPlaces: 3
		});
		if (tooltip === formattedValue) {
			tooltip = undefined;
		}
		return <StringValuedMetric value={formattedValue} tooltip={tooltip} rating={options.rating} />;
	}

	public override formatValueAsText(value: number, options: MetricFormatterOptions): string {
		Assertions.assertNumber(value);
		let formattedValue = NumericValueFormatter.formatNumericMetricAsText(value, this.schemaEntry, options);
		const alwaysShowSign = options.alwaysShowSign ?? false;
		if (alwaysShowSign && value >= 0) {
			formattedValue = '+' + formattedValue;
		}
		return formattedValue;
	}

	/**
	 * Returns the matching color for a given metric, if a rating is available otherwise returns <code>null</code>.
	 *
	 * @returns Html color code that should be used for this metric
	 */
	public static getMetricColor(
		metricAssessmentColor: ETrafficLightColorEntry | null | undefined,
		colorBlindModeEnabled?: boolean
	): string | undefined {
		if (metricAssessmentColor == null) {
			return undefined;
		}
		switch (metricAssessmentColor) {
			case 'GREEN':
				return getColorRepresentationForSemanticColor(TRAFFIC_LIGHT_GREEN, colorBlindModeEnabled);
			case 'YELLOW':
				return getColorRepresentationForSemanticColor(TRAFFIC_LIGHT_YELLOW_ALTERNATIVE, colorBlindModeEnabled);
			case 'RED':
				return getColorRepresentationForSemanticColor(TRAFFIC_LIGHT_RED, colorBlindModeEnabled);
			case 'ORANGE':
				return getColorRepresentationForSemanticColor(TRAFFIC_LIGHT_ORANGE, colorBlindModeEnabled);
			default:
				return undefined;
		}
	}

	/** Formats a numeric metric value as text. */
	private static formatNumericMetricAsText(
		metricValue: number,
		schemaEntry: MetricDirectorySchemaEntry,
		options: NumericFormatterOptions
	): string {
		const { abbreviateValues = true, valueIsBytes = false, decimalPlaces = 1 } = options;

		if (schemaEntry.name === EMetricName.DURATION) {
			return DurationUtils.convertSecondsToFormattedTime(metricValue, abbreviateValues);
		}
		if (MetricProperties.isRatioMetric(schemaEntry)) {
			return NumericValueFormatter.formatAsPercentage(metricValue, {
				maxDecimals: decimalPlaces,
				minDecimals: decimalPlaces
			});
		}
		if (abbreviateValues) {
			return NumericValueFormatter.formatDoubleMetricCompact(
				metricValue,
				schemaEntry,
				valueIsBytes,
				decimalPlaces
			);
		}
		return NumericValueFormatter.formatNumberDefault(metricValue, decimalPlaces);
	}

	/**
	 * Formats the number of a double metric as a string and performs "smart" conversions to keep the number compact.
	 *
	 * @param value The metric value.
	 * @param valueIsBytes - Whether the value is bytes and thus rounding is performed by 1024 steps
	 */
	public static formatDoubleMetricCompact(
		value: number,
		schemaEntry?: MetricDirectorySchemaEntry,
		valueIsBytes = false,
		decimalPlaces = 1
	): string {
		let suffix = '';
		if (schemaEntry && MetricProperties.isRatioMetric(schemaEntry)) {
			value *= 100;
			suffix = '%';
		} else {
			let divider = 1000;
			if (valueIsBytes) {
				divider = 1024;
			}
			const suffixes = ['k', 'M', 'G', 'T', 'P'];
			while (Math.abs(value) > divider) {
				value /= divider;
				suffix = suffixes.shift()!;
			}
		}
		return NumericValueFormatter.formatNumberDefault(value, decimalPlaces) + suffix;
	}

	public override parseFromString(value: string): number {
		return Number(value);
	}

	public override compare(a: number, b: number): number {
		return a - b;
	}
}
