import { Assertions } from 'ts/commons/Assertions';
import { StringValuedMetric } from 'ts/commons/components/MetricFormatters';
import type { MetricDirectorySchemaEntry } from 'typedefs/MetricDirectorySchemaEntry';
import { DateUtils } from './../DateUtils';
import { MetricFormatterBase, type MetricFormatterOptions } from './MetricFormatterBase';

/** A formatter for timestamps. */
export class TimestampMetricFormatter extends MetricFormatterBase<number> {
	public constructor(schemaEntry: MetricDirectorySchemaEntry) {
		super(schemaEntry);
	}

	/**
	 * Formats a long value interpreted as timestamp.
	 *
	 * @param timestamp The unix timestamp
	 */
	public override formatValueAsJsx(timestamp: number, options: MetricFormatterOptions): JSX.Element {
		const formattedValue = TimestampMetricFormatter.formatTimestampAsText(timestamp);
		return <StringValuedMetric value={formattedValue} rating={options.rating} />;
	}

	public override formatValueAsText(timestamp: number): string {
		return TimestampMetricFormatter.formatTimestampAsText(timestamp);
	}

	/**
	 * Formats a long value interpreted as timestamp text.
	 *
	 * @param timestamp The timestamp value
	 */
	public static formatTimestampAsText(timestamp: number): string {
		Assertions.assertNumber(timestamp);
		const date = new Date(timestamp);
		return DateUtils.formatDateTime(date);
	}

	public override parseFromString(value: string): number {
		return parseInt(value);
	}

	public override compare(a: number, b: number): number {
		return a - b;
	}
}
